@use '@oblique/oblique/styles/scss/core/variables';
@use "sass:map";

/* Header if Screen bigger than 1600px */
@media (min-width: 1600px) {
  .ob-header {
    padding: 0 50px; // Padding applied only when screen width is greater than 1600px
  }
}

/* lg: 1240px */
@media (max-width: map.get(variables.$ob-grid-breakpoints, lg)) {
  .hidden-lg {
    display: none;
  }
}

/* lg: 1240px */
@media (min-width: map.get(variables.$ob-grid-breakpoints, lg)) {
  .shown-lg {
    display: none;
  }
}

/* 600px */
@media (min-width: map.get(variables.$ob-grid-breakpoints, sm)) {
  .shown-sm {
    display: none;
  }
}

/* 905px */
@media (min-width: map.get(variables.$ob-grid-breakpoints, md)) {
  .shown-md {
    display: none;
  }
}

@media (min-width: map.get(variables.$ob-grid-breakpoints, lg)) {
  .shown-lg {
    display: none;
  }
}


/* smaller than lg: 1240px */
@media screen and (max-width: map.get(variables.$ob-grid-breakpoints, lg)) {
  .hide-smaller-than-lg {
    display: none;
  }
}

/* smaller than sm: 600px */
@media screen and (max-width: map.get(variables.$ob-grid-breakpoints, sm)) {
  .hide-smaller-than-sm {
    display: none;
  }
}

/* smaller than sm: 905px */
@media screen and (max-width: map.get(variables.$ob-grid-breakpoints, md)) {
  .hide-smaller-than-md {
    display: none;
  }
}

/* smaller than sm: 600px */
@media screen and (min-width: map.get(variables.$ob-grid-breakpoints, sm)) {
  .hide-bigger-than-sm {
    display: none;
  }
}
