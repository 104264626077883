/**
* @file Oblique, The front-end framework for your Swiss branded UI.
* @copyright 2020 - 2024 Federal Office of Information Technology, Systems and Telecommunication FOITT {@link https://www.bit.admin.ch}
* @version 11.3.4 (released on 2024-10-01, supported at least until 2025-02-28)
* @author Oblique team, FOITT, BS-BSC-EN4 <oblique@bit.admin.ch>
* @license MIT {@link https://github.com/oblique-bit/oblique/blob/master/LICENSE}
* @see https://oblique.bit.admin.ch
*/

/**
 * @deprecated since version 11.0.0. Roboto will be replaced by Noto from Oblique 12 on.
 */

/* roboto-100 - latin */
@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 100;
	font-display: fallback;
	src:
		local("Roboto Thin"),
		local("Roboto-Thin"),
		url("../fonts/roboto-v20-latin-100.woff2") format("woff2");
}

/* roboto-100italic - latin */
@font-face {
	font-family: Roboto;
	font-style: italic;
	font-weight: 100;
	font-display: fallback;
	src:
		local("Roboto Thin Italic"),
		local("Roboto-ThinItalic"),
		url("../fonts/roboto-v20-latin-100italic.woff2") format("woff2");
}

/* roboto-300 - latin */
@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 300;
	font-display: fallback;
	src:
		local("Roboto Light"),
		local("Roboto-Light"),
		url("../fonts/roboto-v20-latin-300.woff2") format("woff2");
}

/* roboto-300italic - latin */
@font-face {
	font-family: Roboto;
	font-style: italic;
	font-weight: 300;
	font-display: fallback;
	src:
		local("Roboto Light Italic"),
		local("Roboto-LightItalic"),
		url("../fonts/roboto-v20-latin-300italic.woff2") format("woff2");
}

/* roboto-italic - latin */
@font-face {
	font-family: Roboto;
	font-style: italic;
	font-weight: 400;
	font-display: fallback;
	src:
		local("Roboto Italic"),
		local("Roboto-Italic"),
		url("../fonts/roboto-v20-latin-italic.woff2") format("woff2");
}

/* roboto-regular - latin */
@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 400;
	font-display: fallback;
	src:
		local(Roboto),
		local("Roboto-Regular"),
		url("../fonts/roboto-v20-latin-regular.woff2") format("woff2");
}

/* roboto-500 - latin */
@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-display: fallback;
	src:
		local("Roboto Medium"),
		local("Roboto-Medium"),
		url("../fonts/roboto-v20-latin-500.woff2") format("woff2");
}

/* roboto-500italic - latin */
@font-face {
	font-family: Roboto;
	font-style: italic;
	font-weight: 500;
	font-display: fallback;
	src:
		local("Roboto Medium Italic"),
		local("Roboto-MediumItalic"),
		url("../fonts/roboto-v20-latin-500italic.woff2") format("woff2");
}

/* roboto-700 - latin */
@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 700;
	font-display: fallback;
	src:
		local("Roboto Bold"),
		local("Roboto-Bold"),
		url("../fonts/roboto-v20-latin-700.woff2") format("woff2");
}

/* roboto-700italic - latin */
@font-face {
	font-family: Roboto;
	font-style: italic;
	font-weight: 700;
	font-display: fallback;
	src:
		local("Roboto Bold Italic"),
		local("Roboto-BoldItalic"),
		url("../fonts/roboto-v20-latin-700italic.woff2") format("woff2");
}

/* roboto-900 - latin */
@font-face {
	font-family: Roboto;
	font-style: normal;
	font-weight: 900;
	font-display: fallback;
	src:
		local("Roboto Black"),
		local("Roboto-Black"),
		url("../fonts/roboto-v20-latin-900.woff2") format("woff2");
}

/* roboto-900italic - latin */
@font-face {
	font-family: Roboto;
	font-style: italic;
	font-weight: 900;
	font-display: fallback;
	src:
		local("Roboto Black Italic"),
		local("Roboto-BlackItalic"),
		url("../fonts/roboto-v20-latin-900italic.woff2") format("woff2");
}
