$ob-white: #ffffff;
$ob-black: #000000;

// Red
$ob-red-50: #ffedee;
$ob-red-100: #fae1e2;
$ob-red-200: #ffccce;
$ob-red-300: #fa9da1;
$ob-red-400: #fc656b;
$ob-red-500: #e53940;
$ob-red-600: #d8232a;
$ob-red-700: #bf1f25;
$ob-red-800: #99191e;
$ob-red-900: #801519;

// Green
$ob-green-50: #ecfdf5;
$ob-green-100: #d1fae5;
$ob-green-200: #a7f3d0;
$ob-green-300: #6ee7b7;
$ob-green-400: #34d399;
$ob-green-500: #10b981;
$ob-green-600: #059669;
$ob-green-700: #047857;
$ob-green-800: #065f46;
$ob-green-900: #064e3b;

// Orange
$ob-orange-50: #fff7ed;
$ob-orange-100: #ffedd5;
$ob-orange-200: #fed7aa;
$ob-orange-300: #fdba74;
$ob-orange-400: #fb923c;
$ob-orange-500: #f97316;
$ob-orange-600: #ea580c;
$ob-orange-700: #c2410c;
$ob-orange-800: #9a3412;
$ob-orange-900: #7c2d12;

// Secondary
$ob-secondary-50: #f0f4f7;
$ob-secondary-100: #dfe4e9;
$ob-secondary-200: #acb4bd;
$ob-secondary-300: #828e9a;
$ob-secondary-400: #596978;
$ob-secondary-500: #46596b;
$ob-secondary-600: #2f4356;
$ob-secondary-700: #263645;
$ob-secondary-800: #1c2834;
$ob-secondary-900: #131b22;

// Blue
$ob-blue-100: #dbeafe;
$ob-blue-600: #2563eb;
$ob-blue-700: #1d4ed8;
$ob-blue-800: #1e40af;
$ob-blue-900: #1e3a8a;

// Purple
$ob-purple-500: #8655f6;
$ob-purple-700: #6d28d9;
