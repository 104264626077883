@use '@oblique/oblique/styles/scss/core/variables';
@use "sass:map";


//////////////////////////////////////
////////// FLEX BOX CLASSES //////////
//////////////////////////////////////
.ogp-flex-block {
  display: flex;
  &-direction {
    &-column {
      flex-direction: column;
    }

    &-row {
      flex-direction: row;
    }
  }

  &-align {
    &-horizontal {
      &-center {
        justify-content: center;
      }

      &-start {
        justify-content: flex-start;
      }

      &-end {
        justify-content: flex-end;
      }

      &-space-around {
        justify-content: space-around;
      }

      &-space-between {
        justify-content: space-between;
      }
    }

    &-vertical {
      &-center {
        align-items: center;
      }

      &-start {
        align-items: flex-start;
      }

      &-end {
        align-items: flex-end;
      }

      &-strech {
        align-items: stretch;
      }
    }

    &-center {
      justify-content: center;
      align-content: center;
    }
  }

  &-items {
    &-column-gap {
      margin-bottom: 16px;
    }

    &-row-gap {
      margin-right: 16px;
    }

    &no-shrink {
      flex-shrink: 0;
    }
  }

  &-nowrap {
    flex-wrap: nowrap;
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-size {
    &--1-2 {
      flex-grow: 1;
      max-width: 50%;
    }
  }
}

//////////////////////////////////////
////// CUSTOM FLEX BOX CLASSES ///////
//////////////////////////////////////
.flex-container-ogr {
  display: flex;
  flex-wrap: wrap;

  mat-card-actions {
    flex-direction: row-reverse;
  }

  .pull-right {
    margin-left: auto;
    padding: 16px;
  }
}

.flex-item-ogr {
  flex: 1;
  margin-bottom: 10px;
}

.margin-right {
  margin-right: 10px;
}

/* xl: 1440px */
@media (min-width: map.get(variables.$ob-grid-breakpoints, xl)) {
  .flex-item-ogr {
    flex-basis: calc(25% - 10px); /* 4 cells per row */
    mat-card-actions {
      padding-top: 67px !important;
    }
  }
}

/* lg: 1240px */
@media (max-width: map.get(variables.$ob-grid-breakpoints, lg)) {
  .flex-item-ogr {
    flex-basis: calc(50% - 10px); /* 2 cells per row */
  }
}

/* sm: 600px */
@media (max-width: map.get(variables.$ob-grid-breakpoints, sm)) {
  .flex-item-ogr {
    flex-basis: calc(100% - 10px); /* 1 cell per row */
  }
}