@use '@oblique/oblique/styles/scss/core/palette';
@use '@oblique/oblique/styles/scss/core/variables';
@use './oblique-overwrites';
@use "sass:map";
@use './flexbox';
@use "./media-queries";
@use './grid/grid';

.ob-main-layout {
  padding: 0 !important;

  .title {
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  .hover:hover {
    cursor: pointer;
    background-color: variables.$ob-gray-lighter !important;
  }

  .jumbotron-gray {
    background: variables.$ob-gray-extra-light;
    padding: 40px 16px;
  }

  .jumbotron-white {
    padding: 40px 16px;
  }

  .mdc-card {
    border: 1px solid palette.$ob-secondary-200;
  }

  mat-chip {
    .mdc-evolution-chip__action--presentational {
      cursor: pointer;
    }
  }
}

.max-width-content {
  width: 100%;            /* Full width up to max-width */
  max-width: 1440px;      /* Constrain to 1240px on larger screens */
  margin: 0 auto;         /* Center align the content */
}


.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

/////////////////////////////////////////
//General ogp-container Spacing Options//
/////////////////////////////////////////
.ogp-container {
  margin: 0 16px;
}

/////////////////////////////////////////
//General Global Margin Spacing Options//
/////////////////////////////////////////

// Margin ALL SIDES Spacing
.margin {
  &-0 {
    margin: 0 !important;
  }
  &-xs {
    margin: 10px;
  }

  &-small {
    margin: 20px;
  }

  &-medium {
    margin: 25px;
  }

  &-large {
    margin: 40px;
  }

  &-xl {
    margin: 60px;
  }

  &-2xl {
    margin: 80px;
  }
}

// Margin TOP Spacing
.margin-t {
  &-0 {
    margin-top: 0 !important;
  }
  &-xs {
    margin-top: 10px;
  }

  &-s {
    margin-top: 20px;
  }

  &-m {
    margin-top: 25px;
  }

  &-l {
    margin-top: 40px;
  }

  &-50 {
    margin-top: 50px;
  }

  &-xl {
    margin-top: 60px;
  }

  &-70 {
    margin-top: 70px;
  }

  &-2xl {
    margin-top: 80px;
  }
}

// Margin BOTTOM Spacing
.margin-b {
  &-0 {
    margin-bottom: 0 !important;
  }
  &-xs {
    margin-bottom: 10px;
  }

  &-s {
    margin-bottom: 20px;
  }

  &-m {
    margin-bottom: 25px;
  }

  &-l {
    margin-bottom: 40px;
  }

  &-xl {
    margin-bottom: 60px;
  }

  &-2xl {
    margin-bottom: 80px;
  }
}

// Margin TOP and BOTTOM (Y-AXIS) Spacing
.margin-y {
  &-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  &-xs {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &-s {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &-m {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  &-l {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &-xl {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  &-2xl {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

// Margin LEFT Spacing
.margin-l {
  &-0 {
    margin-left: 0 !important;
  }

  &-xs {
    margin-left: 10px;
  }

  &-s {
    margin-left: 20px;
  }

  &-m {
    margin-left: 25px;
  }

  &-l {
    margin-left: 40px;
  }

  &-xl {
    margin-left: 60px;
  }

  &-2xl {
    margin-left: 80px;
  }
}

// Margin RIGHT Spacing
.margin-r {
  &-0 {
    margin-right: 0 !important;
  }

  &-xs {
    margin-right: 10px;
  }

  &-s {
    margin-right: 20px;
  }

  &-m {
    margin-right: 25px;
  }

  &-l {
    margin-right: 40px;
  }

  &-xl {
    margin-right: 60px;
  }

  &-2xl {
    margin-right: 80px;
  }
}

// Margin LEFT and RIGHT (X-AXIS) Spacing
.margin-x {
  &-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  &-xs {
    margin-left: 10px;
    margin-right: 10px;
  }

  &-s {
    margin-left: 20px;
    margin-right: 20px;
  }

  &-m {
    margin-left: 25px;
    margin-right: 25px;
  }

  &-l {
    margin-left: 40px;
    margin-right: 40px;
  }

  &-xl {
    margin-left: 60px;
    margin-right: 60px;
  }

  &-2xl {
    margin-left: 80px;
    margin-right: 80px;
  }
}

/////////////////////////////////////////
//General Global Padding Spacing Options//
/////////////////////////////////////////

// Padding ALL SIDES Spacing
.padding {
  &-0 {
    padding: 0 !important;
  }

  &-xs {
    padding: 10px;
  }

  &-small {
    padding: 20px;
  }

  &-medium {
    padding: 25px;
  }

  &-large {
    padding: 40px;
  }

  &-xl {
    padding: 60px;
  }

  &-2xl {
    padding: 80px;
  }
}

// Padding TOP Spacing
.padding-t {
  &-0 {
    padding-top: 0 !important;
  }

  &-xs {
    padding-top: 10px;
  }

  &-s {
    padding-top: 20px;
  }

  &-m {
    padding-top: 25px;
  }

  &-l {
    padding-top: 40px;
  }

  &-xl {
    padding-top: 60px;
  }

  &-2xl {
    padding-top: 80px;
  }
}

// Padding BOTTOM Spacing
.padding-b {
  &-0 {
    padding-bottom: 0 !important;
  }

  &-xs {
    padding-bottom: 10px;
  }

  &-s {
    padding-bottom: 20px;
  }

  &-m {
    padding-bottom: 25px;
  }

  &-l {
    padding-bottom: 40px;
  }

  &-xl {
    padding-bottom: 60px;
  }

  &-2xl {
    padding-bottom: 80px;
  }
}

// Padding TOP and BOTTOM (Y-AXIS) Spacing
.padding-y {
  &-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &-xs {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &-s {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &-m {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  &-l {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &-xl {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &-2xl {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

// Padding LEFT Spacing
.padding-l {
  &-0 {
    padding-left: 0 !important;
  }

  &-xs {
    padding-left: 10px;
  }

  &-s {
    padding-left: 20px;
  }

  &-m {
    padding-left: 25px;
  }

  &-l {
    padding-left: 40px;
  }

  &-xl {
    padding-left: 60px;
  }

  &-2xl {
    padding-left: 80px;
  }
}

// Padding RIGHT Spacing
.padding-r {
  &-0 {
    padding-right: 0 !important;
  }

  &-xs {
    padding-right: 10px;
  }

  &-s {
    padding-right: 20px;
  }

  &-m {
    padding-right: 25px;
  }

  &-l {
    padding-right: 40px;
  }

  &-xl {
    padding-right: 60px;
  }

  &-2xl {
    padding-right: 80px;
  }
}

// Padding LEFT and RIGHT (X-AXIS) Spacing
.padding-x {
  &-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &-xs {
    padding-left: 10px;
    padding-right: 10px;
  }

  &-s {
    padding-left: 20px;
    padding-right: 20px;
  }

  &-m {
    padding-left: 25px;
    padding-right: 25px;
  }

  &-l {
    padding-left: 40px;
    padding-right: 40px;
  }

  &-xl {
    padding-left: 60px;
    padding-right: 60px;
  }

  &-2xl {
    padding-left: 80px;
    padding-right: 80px;
  }
}


//Necessary because for some reason the text color of disabled dropdown does not change
.mat-mdc-select-disabled span {
  color: palette.$ob-secondary-300;
}
