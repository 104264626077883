/////////////////////////////////////
//// HEADER ANPASSUNGEN OVERWRITE ////
/////////////////////////////////////

// NAVIGATION OVERWRITE
.ob-master-layout-navigation {
  padding: 0 16px;
  nav{
    max-width: 1440px;      /* Maximale Breite definieren */
    margin: 0 auto;         /* Zentriert den Header */
  }
}

/////////////////////////////////////
//// RADIO BUTTONS  OVERWRITE //////
////////////////////////////////////
mat-radio-group mat-radio-button {
  .mdc-form-field {
    margin-top: 25px;
    max-width: 550px;
  }
}

////////////////////////////////
////TYPOGRAPHY  OVERWRITE //////
////////////////////////////////
/* in figma all of the fonts are defined with pixels and in oblique with rem */
h2 {
  font-size: 32px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}